import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "PDFPage"
}
const _hoisted_2 = { class: "PDFPageInner" }
const _hoisted_3 = {
  class: "PDFPageContentWrapper",
  ref: "ContentWrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PDFSectionTitle = _resolveComponent("PDFSectionTitle")!
  const _component_PDFPageFooter = _resolveComponent("PDFPageFooter")!

  return (_ctx.project)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.$slots.sectionTitle && _ctx.startIndex === 0)
            ? (_openBlock(), _createBlock(_component_PDFSectionTitle, { key: 0 }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "sectionTitle")
                ]),
                _: 3
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              class: _normalizeClass(["PDFPageContent", _ctx.contentClasses]),
              ref: "Content"
            }, [
              (!_ctx.elementsList?.length)
                ? _renderSlot(_ctx.$slots, "default", {
                    key: 0,
                    addAsPending: _ctx.addAsPending,
                    removeAsPending: _ctx.removeAsPending
                  })
                : _createCommentVNode("", true)
            ], 2)
          ], 512),
          _createVNode(_component_PDFPageFooter)
        ])
      ]))
    : _createCommentVNode("", true)
}