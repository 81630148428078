
import PDFPageFooter from '@/components/pdfs/partials/PDFPageFooter.vue'
import PDFSectionTitle from '@/components/pdfs/partials/PDFSectionTitle.vue'
import { ENV } from '@/globals/javascript/_utils/env'
import { currentStore, pdfStore } from '@/store'
import { mapActions, mapState } from 'pinia'
import { defineComponent, PropType } from 'vue'

type UnionElement = Node | Element | HTMLElement

export default defineComponent({
  name: 'PDFPage',
  emits: ['page-is-full', 'all-elements-added', 'elements-loaded'],
  props: {
    contentClasses: {
      type: [String, Object, Array],
      default: '',
      required: false,
    },
    elementsList: {
      type: Array as PropType<UnionElement[]>,
      default: undefined,
      required: false,
    },
    startIndex: {
      type: Number,
      default: 0,
      required: false,
    },
    context: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      startIndexes: [0],
      pageMaxHeight: 0,
      pageElements: [] as UnionElement[],
    }
  },
  computed: {
    ...mapState(currentStore, ['project']),
    ...mapState(pdfStore, ['processing']),
    isStatic() {
      return this.elementsList === undefined
    },
  },
  watch: {
    elementsList: {
      handler(data) {
        if (this.isStatic) {
          return
        }

        if (data.length) {
          this.$nextTick(this.fillUpPage)
        }
      },
      immediate: true,
      deep: true,
    },
    processing: {
      handler(references) {
        if (this.isStatic) {
          return
        }

        const pendingImageCount = references[this.context]?.images

        if (pendingImageCount === undefined) {
          return
        }

        if (!pendingImageCount && !this.elementsList?.length) {
          this.$nextTick(() => {
            this.emitSlottedElements()
          })
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(pdfStore, ['addAsPending', 'removeAsPending']),
    emitSlottedElements() {
      if (this.isStatic) {
        return
      }

      this.$nextTick(() => {
        const contentRef = this.$refs.Content as Element
        this.$emit('elements-loaded', contentRef?.children ?? null)
      })
    },
    fillUpPage() {
      if (this.isStatic) {
        return
      }

      // Get the right max page height
      if (this.pageMaxHeight === 0) {
        const contentWrapRef = this.$refs.ContentWrap as Element

        if (!contentWrapRef) {
          return
        }

        const { height } = contentWrapRef.getBoundingClientRect()

        this.pageMaxHeight = Math.ceil(height)
      }

      const nextElementIndex = this.startIndex + this.pageElements.length
      const contentRef = this.$refs.Content as Element

      if (!contentRef) {
        return
      }

      const { height } = contentRef.getBoundingClientRect()

      if (height > this.pageMaxHeight) {
        this.onPageIsFull(nextElementIndex)
        return
      }

      const nextElement = this.elementsList?.[nextElementIndex]

      // Check if there are more to add
      if (!nextElement) {
        this.$emit('all-elements-added')
        this.removeAsPending(this.context, 'pages')
        return
      }

      // Add next sample to page
      this.pageElements.push(nextElement)
      contentRef.appendChild(nextElement)

      // Do it again
      this.fillUpPage()
    },
    onPageIsFull(nextElementIndex: number) {
      const contentRef = this.$refs.Content as Element

      if (!contentRef?.lastChild) {
        return
      }

      // Remove from array of inserted elements
      this.pageElements.pop()

      // Get height of element that overflows pdf page
      const { height } = (
        contentRef.lastChild as Element
      ).getBoundingClientRect()

      // If the single element height is greater than pageMaxHeight
      if (height > this.pageMaxHeight) {
        // Send error to sentry
        this.onSingleElementTallerThanPage(contentRef.lastChild as Element)

        // Skip the element that is to big and continue the render
        // This will render the element on a single page, but hide
        // the overflowing part
        this.$emit('page-is-full', nextElementIndex)

        return
      }

      contentRef.removeChild(contentRef.lastChild)

      this.$emit('page-is-full', nextElementIndex - 1)
    },
    onSingleElementTallerThanPage(culprit: Element) {
      if (ENV.isDevelopment) {
        console.error('Error!: An element is higher than max height', culprit)
      }
    },
  },
  components: { PDFPageFooter, PDFSectionTitle },
  created() {
    if (this.startIndex === 0) {
      this.addAsPending(this.context, 'pages')
    }
  },
  mounted() {
    if (this.isStatic) {
      this.$nextTick(() => {
        this.removeAsPending(this.context, 'pages')
      })
    }
  },
})
