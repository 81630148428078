
import Spinner from '@/components/Spinner.vue'
import { ENV } from '@/globals/javascript/_utils/env'
import { HOST } from '@/globals/javascript/_utils/host'
import { currentStore, pdfStore, translationsStore } from '@/store'
import { captureException, withScope } from '@sentry/minimal'
import { Severity } from '@sentry/types'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import pkgJson from '/package.json'

export default defineComponent({
  name: 'PDFPreview',
  emits: ['page-numbers-added', 'status-changed'],
  props: {
    referenceName: {
      type: String,
      required: true,
    },
    initFetch: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      created: this.mixGetDate(null, null),
      openAsPreview: this.preview,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      timeout: null as any,

      pdf: {
        pageNumbersAdded: false,
        tocReady: false,
        requested: false,
        hasPendingElements: true,
        status: 'process:idle',
      },
    }
  },
  computed: {
    ...mapState(translationsStore, ['selectedLanguage']),
    ...mapState(pdfStore, [
      'processing',
      'pageNumbersAdded',
      'tocReady',
      'configs',
    ]),
    ...mapState(currentStore, {
      projectID: (store) => store.project?.id ?? '',
      projectData: ({ project }) => project?.data ?? null,
      isScreening: ({ project }) => project?.data.isScreening ?? false,
    }),
    showDownloadButton() {
      const allowForScreening =
        this.isScreening && this.referenceName === 'screeningReport'
      const allowForNonScreening =
        !this.isScreening && this.referenceName !== 'screeningReport'

      return allowForScreening || allowForNonScreening
    },
    pdfConfig() {
      return this.configs.all[this.referenceName]
    },
    pdfReady() {
      return (
        !this.pdf.hasPendingElements &&
        this.pdf.pageNumbersAdded &&
        this.pdf.tocReady
      )
    },
    tocBuildApproved() {
      return !this.pdf.hasPendingElements && this.pdf.pageNumbersAdded
    },
    initiatePDFRequest() {
      return this.pdf.requested && this.pdfReady
    },
  },
  watch: {
    processing: {
      handler(data) {
        if (this.timeout) {
          clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(() => {
          const ref = data[this.pdfConfig.reference]

          const sections = ref.sections ?? 0
          const images = ref.images ?? 0
          const pages = ref.pages ?? 0

          const pendingElementsCount = images + pages

          if (pendingElementsCount) {
            return
          }

          if (!this.pageNumbersAdded[this.pdfConfig.reference]) {
            this.insertPageNumbersInFooter()
          }

          if (sections === 0) {
            this.$nextTick(() => {
              this.pdf.hasPendingElements = false
            })
          }
        }, 500)
      },
      deep: true,
    },
    pageNumbersAdded: {
      handler(data) {
        this.pdf.pageNumbersAdded = data[this.pdfConfig.reference] ?? false
      },
      deep: true,
    },
    tocBuildApproved(val) {
      if (val && this.tocReady[this.pdfConfig.reference] === undefined) {
        this.pdf.tocReady = true
      }
    },
    tocReady: {
      handler(data) {
        this.pdf.tocReady = data[this.pdfConfig.reference] ?? false
      },
      deep: true,
    },

    initFetch: {
      handler(bool: boolean) {
        this.pdf.requested = bool
      },
      immediate: true,
    },
    initiatePDFRequest(bool: boolean) {
      if (bool) {
        this.getPDF()
      }
    },
    'pdf.status'(str) {
      this.$emit('status-changed', str)

      const shouldReset = ['error:', 'success:'].some(
        (x) => str.indexOf(x) === 0
      )

      if (shouldReset) {
        this.resetStatus()
      }
    },
  },
  methods: {
    ...mapActions(pdfStore, [
      'setPageNumberState',
      'prepState',
      'addAsPending',
      'removeAsPending',
    ]),
    onClickPDFDownload() {
      this.pdf.requested = true
    },
    resetStatus() {
      setTimeout(() => {
        this.pdf.status = 'process:idle'
        this.pdf.requested = false
      }, 1000)
    },

    insertPageNumbersInFooter() {
      const pdfPageFooters = [...this.$el.querySelectorAll('.PDFPageFooter')]

      pdfPageFooters.forEach((f, index) => {
        const pageNumber = index + 1
        f.querySelector('.js-pdf-page-number').innerHTML = pageNumber
      })

      this.setPageNumberState(this.pdfConfig.reference, true)
    },
    async fetchPDFContent() {
      const pdfPages = [
        ...(this.$refs.Content as Element).querySelectorAll('.PDFPage'),
      ]

      if (!pdfPages.length) {
        // Error handling will be contained in the main function.
        return
      }

      // Grab CSS made for PDF
      const cssFileFetch = await fetch(`/pdfs/${this.pdfConfig.css}.css`)
      const cssContent = await cssFileFetch.text()

      return {
        pdfPages: pdfPages.map((x) => x.outerHTML),
        css: `<style>${cssContent}</style>`,
      }
    },
    async getPDF() {
      this.pdf.status = 'process:creating-pdf'
      let response: Response

      const pdfContent = await this.fetchPDFContent()

      if (!pdfContent) {
        this.pdf.status = 'error:html-fetch-failed'

        withScope((scope) => {
          scope.setLevel(Severity.Critical)
          captureException('ERROR: PDF report html was not found.')
        })
        return
      }

      try {
        const bodyData = {
          documentHTML: pdfContent,
          path: {
            accountID: this.projectData?.accountID ?? '',
            projectID: this.projectID,
            screeningYear: this.projectData?.screeningYear.toString() ?? '',
            screeningMonth: this.projectData?.screeningMonth.toString() ?? '',
            storageName: this.pdfConfig.storageName,
            fileName: this.pdfConfig.fileName,
            locale: this.selectedLanguage?.locale ?? 'da-DK',
            reference: this.pdfConfig.reference,
            created: Number(this.created) ?? -1,
            lastUpdated: this.projectData?.lastUpdated?._seconds ?? -1,
            pdfVersion:
              Number(pkgJson.pdfVersions[this.pdfConfig.versionReference]) ??
              -1,
          },
        }

        response = await fetch(`${HOST.cloudFunction}/online_v2_reportPDF`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify(bodyData),
        })
      } catch (e) {
        this.pdf.status = 'error:pdf-creation-failed:1'
        captureException(e)

        return
      }

      // Ensure creation success
      if (!response.ok) {
        this.pdf.status = 'error:pdf-creation-failed:2'

        return
      }

      this.pdf.status = 'process:downloading'

      // Get pdf file path
      const { storagePathRef } = await response.json()
      let pdfPath = ''

      try {
        pdfPath = this.mixGetFileUrl({
          path: storagePathRef,
          filename: this.pdfConfig.fileName || 'Report.pdf',
          download: !this.openAsPreview,
        })
      } catch (e) {
        this.pdf.status = 'error:path-creation-failed:1'
        captureException(e)

        return
      }

      if (typeof pdfPath === 'undefined') {
        this.pdf.status = 'error:path-creation-failed:2'

        return
      }

      // Open in new tab
      if (this.openAsPreview) {
        window.open(pdfPath)
        this.pdf.status = 'success:preview-opened'

        return
      }

      // Download
      fetch(pdfPath)
        .then((res) => res.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((url) => {
          const downloadLink = document.createElement('a')

          document.body.appendChild(downloadLink)

          downloadLink.download = this.pdfConfig.fileName || 'Report.pdf'
          downloadLink.href = url
          downloadLink.click()

          this.pdf.status = 'success:download-started'

          document.body.removeChild(downloadLink)
        })
        .catch((e) => {
          captureException(e)
        })
    },
  },
  components: { Spinner },
  created() {
    this.prepState(this.pdfConfig.reference)

    if (ENV.isDevelopment) {
      const noPKGJsonPDFMatch = !Object.keys(pkgJson.pdfVersions).includes(
        this.pdfConfig.versionReference
      )

      noPKGJsonPDFMatch &&
        console.error(
          'Error!: "%s" is not found in package.json under "pdfVersions".',
          this.pdfConfig.versionReference
        )
    }
  },
  beforeUnmount() {
    this.setPageNumberState(this.pdfConfig.reference, false)
    this.pdf.tocReady = false
  },
})
