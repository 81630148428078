
import Image from '@/components/Image.vue'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import Unit from '@/globals/javascript/models/proUnit/Unit'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PDFFloorplanBlock',
  props: {
    sample: {
      type: Object as PropType<ProSample>,
      required: true,
    },
    showUnitText: {
      type: Boolean,
      default: false,
    },
    context: {
      type: String,
      required: true,
    },
  },
  computed: {
    unit() {
      const { floorPlanUnit } = this.sample

      // If no floor plan is assigned
      if (!floorPlanUnit) {
        return null
      }

      return Unit.getUnitById(floorPlanUnit)
    },
    image() {
      const { otherScreeningID } = this.sample

      // Don't show floor plans from other projects
      if (otherScreeningID) {
        return null
      }

      if (!this.unit) {
        return null
      }
      // Get first floorplan
      const [floorPlan] = this.unit.getFloorPlan()

      return floorPlan
    },
    imageStyle() {
      const { x, y } = this.sample.floorPlanPosition

      if (x === null || y === null) {
        return {}
      }

      return { objectPosition: `${x}% ${y}%` }
    },
    samplePosition() {
      const { x, y } = this.sample.floorPlanPosition

      if (x === null || y === null) {
        return {}
      }

      // Floorplan images are always saved in Portrait (via PRO)
      // - Simply assign x on x-axis and y on y-axis
      return {
        top: `${y}%`,
        left: `${x}%`,
      }
    },
  },
  components: { Image },
})
