
import Image from '@/components/Image.vue'
import Building from '@/globals/javascript/models/proUnit/Building'
import { currentStore } from '@/store'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import PDFPage from '@/components/pdfs/PDFPage.vue'
import { HOST } from '@/globals/javascript/_utils/host'

export default defineComponent({
  name: 'PDFFrontpage',
  emits: ['completed'],
  props: {
    title: {
      type: String,
      required: true,
    },
    createdTimestamp: {
      type: Number,
      required: true,
    },
    context: {
      type: String,
      required: true,
    },
  },
  data() {
    const qrBoxText = this.mixWB('PDF_FRONT_PAGE_LINK_TO_ONLINE_TEXT', [
      this.link('ProjectRoot'),
    ])

    const buildingID = this.context.split('-')[1] ?? ''

    return {
      created: { _seconds: this.createdTimestamp, _nanoseconds: -1 },
      buildingID,
      qrBoxText,
      states: {
        qrLoaded: false,
        isMounted: false,
        isBelfor: false,
      },
    }
  },
  computed: {
    ...mapState(currentStore, [
      'project',
      'projectAddress',
      'account',
      'mapper',
    ]),
    ...mapState(currentStore, {
      activeBuildings: (store) => store.units.active.buildings,
      ownerAssociation: (store) => {
        const unit = store.units.active.buildings[0]

        const landOwnerTextParts: string[] = [
          unit.getLandRegistryNumber(),
          unit.getLandOwnerAssosiationName(),
        ].filter(Boolean)

        return landOwnerTextParts.length ? landOwnerTextParts.join(' - ') : ''
      },
    }),
    reportImage() {
      if (this.buildingID) {
        const [building] = this.buildings

        return building.getProfileImages()[0] ?? null
      }

      return this.project?.getProfileImage() ?? null
    },
    buildings() {
      if (this.buildingID) {
        return this.activeBuildings.filter((x) => x.id === this.buildingID)
      }

      return this.activeBuildings
    },
    qrRoute(): string {
      return HOST.online + this.$router.resolve({ name: 'ProjectRoot' }).href
    },
    componentRendered() {
      return this.states.qrLoaded && this.states.isMounted
    },
  },
  watch: {
    componentRendered: {
      handler(bool) {
        if (bool) {
          this.$emit('completed')
        }
      },
      immediate: true,
    },
  },
  methods: {
    buildingMeta(building: Building) {
      const type = building.getBuildingTypeCodeText() ?? null
      const builtArea = building.getBuiltArea()
        ? `${this.mixWB('BUILT_UP_AREA')}: ${building.getBuiltArea()}`
        : `${this.mixWB('BUILT_UP_AREA')}: ${this.mixWB('NOT_SPECIFIED')}`
      const yearBuilt = building.getYearBuilt()
        ? `${this.mixWB('YEAR_OF_CONSTRUCTION')}: ${building.getYearBuilt()}`
        : `${this.mixWB('YEAR_OF_CONSTRUCTION')}: ${this.mixWB(
            'NOT_SPECIFIED'
          )}`
      const yearRemodelled = building.getRemodelingYear()
        ? `${this.mixWB('REMODELING_YEAR')}: ${building.getRemodelingYear()}`
        : null

      return [type, builtArea, yearBuilt, yearRemodelled]
        .filter(Boolean)
        .join(' - ')
    },
    link(name: string) {
      const { protocol, host } = window.location
      return `${protocol}//${host}${this.$router.resolve({ name }).href}`
    },
  },
  components: { Image, PDFPage },
  created() {
    this.states.isBelfor = this.account?.id === 'uDQYwpFGwFrwkHfaVSWg'
  },
  mounted() {
    this.$nextTick(() => {
      this.states.isMounted = true
    })
  },
})
