
import { union } from 'lodash-es'
import { defineComponent, PropType } from 'vue'

type UnionElement = Node | Element | HTMLElement

export default defineComponent({
  name: 'PDFPageRender',
  emits: ['finishedRendering'],
  props: {
    allElements: {
      type: Array as PropType<UnionElement[]>,
      required: false,
      default: () => [],
    },
    disableAutoRender: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      startIndexes: [] as number[],
      elementsList: [] as UnionElement[],
    }
  },
  watch: {
    allElements: {
      handler(data) {
        if (data.length) {
          this.elementsList = data
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    onPageIsFull(nextStartIndex: number) {
      this.startIndexes = union(this.startIndexes, [nextStartIndex])
      this.$emit('finishedRendering')
    },
    convertToElementsArray(elements: HTMLCollection) {
      if (!this.disableAutoRender && !this.elementsList.length) {
        this.elementsList = [...elements] as Element[]
      }
    },
    resetPages() {
      this.startIndexes = []

      requestAnimationFrame(() => {
        this.startIndexes = [0]
      })
    },
  },
  created() {
    this.resetPages()
  },
})
