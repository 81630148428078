import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "PDFFloorplanBlock"
}
const _hoisted_2 = { class: "Floorplan" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!

  return (_ctx.sample.sampleNumber)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Image, {
            context: _ctx.context,
            image: _ctx.image,
            transformation: 'resize_min_240',
            loading: "eager",
            dimensions: {width: 130, height: 130},
            "img-style": _ctx.imageStyle
          }, null, 8, ["context", "image", "img-style"]),
          _createElementVNode("div", {
            class: _normalizeClass(["SampleLocation", _ctx.sample.getOverallResultClass()]),
            style: _normalizeStyle(_ctx.samplePosition)
          }, null, 6)
        ]),
        (_ctx.showUnitText)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.sample.sampleTypeID) + " (" + _toDisplayString(_ctx.unit?.getFullTitle()) + ")", 1))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}